<template>
  <div class="page">
    <section class="banner">
      <h2 class="title"><strong>橙仕ZZ</strong><br class="mShow" />智能双足机器人系列</h2>
      <picture style="width: 100%">
        <!-- <source
          srcset="https://alihtml.juzhen.cn/juzhen/assets/img/op/m/zz.jpg 1x"
          media="all and (max-width: 500px)"
          type="image/jpg"
        /> -->
        <img src="../../assets/v3/zz/banner.jpg" alt="橙仕ZZ智能双足机器人系列" width="1920" height="1080" />
      </picture>
    </section>
   
    <section class="box zz">
      <h2><strong>橙仕ZZ</strong> 填补市场空白 <br class="mShow" />解决“最后一公里”难题</h2>
      <p>橙仕ZZ智能双足机器人集成高性能姿态传感器(IMU)高速、高精度的姿态检测</p>
      <p>高性能大脑(Raspberry 4B)高速姿态结算，灵活扩展、敏捷跳跃</p>
      <p>设计用于混合环境，轮子和腿的组合使其能够在平坦的地形上快速移动，并通过跳跃克服障碍</p>
      <p>其小巧的外形使其适合狭窄的空间，并显示出高敏捷度的室内外移动任务的潜力</p>
      <img src="../../assets/v3/zz/zz.jpg" class="img" width="938" height="567" alt="橙仕ZZ" />
    </section>
    <section class="box caddie">
      <div class="summary">
        <h2><img src="../../assets/v3/zz/robocaddie.png" width="474" height="66" alt="RoboCaddie" /><strong>橙仕ZZ</strong> 智能高尔夫球童</h2>
        <p>橙仕ZZ智能高尔夫球童，智能跟随球员，能在草坪、斜坡上行动自如，为球员携带和管理球及球杆，并能识别球员手势随时提供球和球杆服务。是一名令客人期待，信赖的高效率的智能球童。</p>
      </div>
      <div class="imgWrap">
        <img src="../../assets/v3/zz/caddie.jpg" class="img" width="938" height="567" alt="RoboCaddie" />
      </div>
    </section>
    <section class="box express">
      <div class="summary">
        <h2><img src="../../assets/v3/zz/roboexpress.png" width="494" height="78" alt="RoboExpress" /><strong>橙仕ZZ</strong> 智能配送机器人</h2>
        <p>橙仕双足机器人能适应末端配送的复杂环境，填补无人车无法到达的末端物流空白，与市面上其他末端物流方案相比有明显的竞争优势，开创末端配送新时代！</p>
      </div>
      <div class="imgWrap">
        <img src="../../assets/v3/zz/express.jpg" class="img" width="800" height="534" alt="RoboExpress" />
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name:'ZZ',
}
</script>
<style lang="scss" scoped>
.page {
  font-size: 20px;
  line-height: 30px;
  color: #222226;
  text-align: center;
}

.banner {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;

  .title {
    position: absolute;
    top: 28%;
    left: 0;
    font-size: 80px;
    width: 100%;
    line-height: 110px;
    text-align: center;

    strong {
      font-weight: bold;
    }
  }

  img {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto;
  }
}

.box {
  display: flex;
  margin: 0 auto;
  padding: 96px 20px;
  align-items: center;
  max-width: 1520px;
  max-height: 760px;

  h2 {
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 46px;
    text-align: center;

    img {
      display: block;
      margin-bottom: 8px;
    }
  }

  .summary {
    text-align: left;

    p {
      text-indent: 2em;
    }
  }

  .imgWrap {
    flex: 1;

    .img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

.zz {
  padding: 0 20px;
  height: 960px;
  max-height: none;
  flex-direction: column;
  justify-content: flex-end;

  .img {
    margin-top: 36px;
  }
}

.caddie {
  flex-direction: row-reverse;

  .summary {
    margin-left: 120px;
    width: 474px;

    h2 {
      text-align: right;
    }
  }
}

.express {
  .summary {
    margin-right: 80px;
    width: 494px;
  }
}

@media only screen and (max-width: 500px) {
  .page {
    padding-bottom: 48px;
    font-size: 14px;
    line-height: 20px;
  }

  .banner {
    .title {
      top: 24%;
      font-size: 24px;
      line-height: 36px;
    }

    img {
      object-fit: cover;
      min-height: 400px;
    }
  }

  .box {
    margin-top: 60px;
    padding: 0 24px;

    h2 {
      margin-bottom: 12px;
      font-size: 24px;
      line-height: 36px;
    }

    .summary {
      text-align: left;

      p {
        text-indent: 0;
      }
    }

    .imgWrap {
      margin-bottom: 24px;
    }
  }

  .zz {
    height: auto;

    .img {
      margin-top: 36px;
      width: 100%;
      height: auto;
    }
  }

  .caddie {
    flex-direction: column-reverse;

    .summary {
      margin-left: 0;
      width: 100%;

      h2 {
        text-align: right;

        img {
          width: auto;
          height: 33px;
        }
      }
    }
  }

  .express {
    flex-direction: column-reverse;

    .summary {
      margin-right: 0;
      width: 100%;

      h2 {
        text-align: right;

        img {
          width: auto;
          height: 39px;
        }
      }
    }
  }
}
</style>
